export default [
    {
        company: 'Centivizer',
        role: 'Project Manager',
        startDate: 'May 2023',
        endDate: 'April 2024',
        description: [
            "Overseeing the development and progress of the BrainTagger game"
        ],
        logo: 'CentivizerLogo.png'
    },
    {
        company: 'FinTorch',
        role: 'Co-founder & Frontend Developer',
        startDate: 'May 2023',
        endDate: 'September 2023',
        description: [
            "Led the overhaul and development of a Next.js website using React, TypeScript, and Tailwind, improving mobile accessibility for 50%+ users.",
            "Engineered secure and user-friendly sign up, log in, and profile pages leveraging NextAuth.js, enhancing user accessibility and convenience on the platform.",
            "Introduced a new feature to allow users to choose from a list of suggested/related questions, resulting in a 40% increase in user engagement."
        ],
        logo: 'FinTorchLogo.png'
    },
    {
        company: 'Amazon',
        role: 'Software Development Engineer Intern',
        startDate: 'May 2023',
        endDate: 'July 2023',
        description: [
            "Spearheaded the design and development of a test suite using the Python AWS SDK, pytest, PyMongo, and AWS Fargate to measure downtime caused by DocumentDB engine upgrades across 15 instance classes.",
            "Implemented an automatic response system through the Amazon CloudWatch client to raise alarms and auto-generate crash tickets when the downtime exceeded a predetermined threshold to ensure efficient incident management.",
            "Enhanced system performance by 1500% through multi-processing and multi-threading functionalities, enabling concurrent measurement of writer and reader downtimes for each instance."
        ],
        logo: 'AmazonLogo.png'
    },
    {
        company: 'Security Compass',
        role: 'Software Engineer Intern',
        startDate: 'May 2022',
        endDate: 'August 2022',
        description: [
            'Introduced critical enhancements to 2 Django API endpoints in response to client requests, leading to recognition in the company’s Employee Spotlight of the Month.',
            'Integrated 3 key behave steps in Python and 10+ API tests using Gherkin/Cucumber to ensure 85%+ code coverage.',
            'Improved UI/UX of a diagrammatic threat modelling tool using React and Cypress, contributing to its continued popularity among 80% of the firm’s client base.'
        ],
        logo: 'SCLogo.png'
    },
    {
        company: 'Navikenz',
        role: 'Software Engineer Intern',
        startDate: 'June 2021',
        endDate: 'September 2021',
        description: [
            'Engineered an optimization tool in Python for a proprietary solution, reducing computation time from approximately one week to nearly two seconds, backed by unit tests achieving 90% code coverage.',
            'Led the architecture and development of a RESTful web service using FastAPI in an agile team of 5, earning exceptional commendation from the CEO for superior performance and functionality.',
            'Constructed the UML data model and database schema for the web service and implemented all CRUD operations for the MongoDB instance using PyMongo, establishing an efficient data management system.'
        ],
        logo: 'NavikenzLogo.png'
    }
]